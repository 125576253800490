import { linkImageOnMouseClick, linkImageOnMouseOut, linkImageonMouserOver } from '../helpers.ts'

interface Breadcrumb {
  text: string
  link?: string
}

export interface SearchResult {
  currentSection: string
  date: string
  breadcrumbs: Breadcrumb[]
  title: string
  link: string
  description: string
  image?: string
}
export const ResultSearchZueriEntry = (props: { entries: SearchResult[]; urlPrefix: string }) => {
  const searchResult = props.entries
  console.log(searchResult)
  return searchResult.map((result, index) => (
    <div key={index}>
      {result.currentSection && result.currentSection !== searchResult[index - 1]?.currentSection && (
        <>
          <hr />
          <div className={'section'}>{result.currentSection}</div>
        </>
      )}
      <hr />
      {result.image && (
        <img
          onMouseOver={linkImageonMouserOver}
          onMouseOut={linkImageOnMouseOut}
          onClick={linkImageOnMouseClick}
          loading="lazy"
          src={result.image || result.link}
          alt=""
          style={{ transform: 'scale(1)' }}
        />
      )}

      <div className={'breadcrumb'}>
        {result.date && <span>{result.date}</span>}
        {result.date && result.breadcrumbs.length > 0 && <br />}
        {result.breadcrumbs.map((breadcrumb, index) => (
          <span key={index}>
            {breadcrumb.link ? (
              <>
                <a href={`${props.urlPrefix}${breadcrumb.link}`} target={'_blank'}>
                  {breadcrumb.text}
                </a>
              </>
            ) : (
              breadcrumb.text
            )}{' '}
            {index < result.breadcrumbs.length - 1 && '>'}{' '}
          </span>
        ))}
      </div>

      <h1>
        <a href={`${props.urlPrefix}${result.link}`} target={'_blank'}>
          {result.title}
        </a>
      </h1>
      <p className={'description'}>{result.description}</p>
    </div>
  ))
}
